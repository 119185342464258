import React, { useState, useRef, useContext } from "react"
import { PageContext, PageData } from "../PageLayout"
import styled from "styled-components/macro"
import { Spring } from "react-spring/renderprops"
import color from "../scripts/colors"
import { Button } from "../GlobalStyles"
import { FaCaretDown } from "react-icons/fa"
import "fontsource-open-sans/600.css"

type ButtonToggleTypes = {
  listVis?: boolean
}

const ButtonWrap = styled.div`
  display: none;
  flex-direction: column;
  position: relative;
  z-index: 1000;
  @media screen and (min-width: 1400px) {
    display: flex;
  }
  svg {
    transition: all 0.25s;
    transform: ${(props: ButtonToggleTypes) =>
      props.listVis ? "rotate(-180deg)" : null};
  }
`

const LinkList = styled.ul`
  display: block;
  font-weight: 300;
  margin: 0 -5px;
  padding: 0;
  height: 0;
  list-style-type: none;
  position: absolute;
  top: 45px;
  overflow-y: hidden;
  background-color: ${color("grey", "dk")};
  li {
    background-color: ${color("grey")};
    margin: 0 5px 5px;
    color: white;
    line-height: 1.3em;
    letter-spacing: 0.1em;
    position: relative;
    padding: 15px 20px 15px 30px;
    &:first-child {
      margin-top: 5px;
    }
    a {
      margin-left: 0;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -5px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-left: 17px solid ${color("grey", "dk")};
    }
  }
`

const CovidButton = () => {
  const pageData: PageData = useContext(PageContext)
  const { covidGoogleSheetUrl } = pageData

  const [listVis, setListVis] = useState(false)
  const listRef = useRef(null)

  return (
    <ButtonWrap listVis={listVis}>
      <Button onClick={() => setListVis(!listVis)}>
        Covid Info <FaCaretDown />
      </Button>
      <Spring
        from={{ height: 0 }}
        to={{
          height: listVis ? "auto" : 0,
        }}
        onStart={() => {
          listVis && (listRef.current.style.zIndex = "1000")
        }}
        onRest={() => {
          !listVis && (listRef.current.style.zIndex = "-1")
        }}
      >
        {props => (
          <LinkList style={props} ref={listRef}>
            <li>
              <a href={covidGoogleSheetUrl} target="_blank" rel="nofollow">
                Facility Status
              </a>
            </li>
            <li>
              <a
                href="https://www.coronavirus.in.gov/2393.htm"
                target="_blank"
                rel="nofollow"
              >
                Dpt. of health Guidelines
              </a>
            </li>
            <li>
              <a
                href={
                  "/2020-WoodlandManor-Employee-Return-To-Work-Criteria.pdf"
                }
                download
                target="_blank"
                rel="nofollow"
              >
                Return to Work Guidelines
              </a>
            </li>
          </LinkList>
        )}
      </Spring>
    </ButtonWrap>
  )
}

export default CovidButton
