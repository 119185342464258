import React, { useContext, useState, useRef } from "react"
import { Link } from "gatsby"
import styled from "styled-components/macro"
import { Spring } from "react-spring/renderprops"
import color from "../scripts/colors"
import { Button, PageMargin } from "../GlobalStyles"
import { PageContext, PageData } from "../PageLayout"
import "fontsource-open-sans/600.css"
import { FaBars, FaTimes } from "react-icons/fa"
import { useMediaQuery } from "react-responsive"

const HeadeBottomStyles = styled.nav`
  background-color: ${color("grey", "dk")};
  position: relative;
  display: flex;
  @media screen and (min-width: 1400px) {
    width: 100%;
    display: block;
  }
`

const HeaderBottomMargin = styled(PageMargin)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  @media screen and (min-width: 1400px) {
    display: block;
  }
`

const NavList = styled(PageMargin)`
  background-color: ${color("grey", "dk")};
  position: absolute;
  display: block;
  top: 62px;
  right: -1px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  width: 250px;
  list-style-type: none;
  padding: 0;
  overflow-y: hidden;
  height: 0;
  @media screen and (min-width: 1400px) {
    position: relative;
    top: 0;
    right: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: initial;
    height: auto;
    width: auto;
  }
  li {
    position: relative;
    padding: 15px calc(35px - 0.1em) 15px 15px;
    margin: 0 5px 5px 5px;
    color: ${color("link")};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px;
    background-color: ${color("grey")};
    text-align: right;
    line-height: 1.3em;
    letter-spacing: 0.1em;
    &:first-child {
      margin-top: 5px;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -5px;
      border-top: 17px solid transparent;
      border-bottom: 17px solid transparent;
      border-right: 17px solid ${color("grey", "dk")};
    }
    &.Mobile {
      @media screen and (min-width: 1400px) {
        display: none;
      }
    }
    @media screen and (min-width: 1400px) {
      background-color: initial;
      padding: 20px 0 20px 40px;
      margin: 0;
      line-height: initial;
      letter-spacing: initial;
      &:first-child {
        margin-top: 0;
      }
      &::after {
        display: none;
      }
    }
  }
`

const MenuToggleStyle = styled(Button)`
  display: flex;
  right: 0;
  margin: 10px;
  padding: 7px;
  font-size: 25px;
  @media screen and (min-width: 1400px) {
    display: none;
  }
`

const MenuToggle = ({ listVis, setListVis }) => {
  return (
    <MenuToggleStyle onClick={() => setListVis(!listVis)}>
      {!listVis ? <FaBars /> : <FaTimes />}
    </MenuToggleStyle>
  )
}

const HeaderBottom = () => {
  const pageData: PageData = useContext(PageContext)
  const { seniorMessagesUrl, covidGoogleSheetUrl } = pageData

  const [listVis, setListVis] = useState(false)
  const listRef = useRef(null)

  const isDesktop = useMediaQuery({
    query: "(min-width: 1400px)",
  })

  const initHeight = isDesktop ? "auto" : 0
  const initZindex = isDesktop ? 0 : -1

  return (
    <HeadeBottomStyles>
      <HeaderBottomMargin>
        <MenuToggle listVis={listVis} setListVis={setListVis} />
        <Spring
          from={{ height: initHeight }}
          to={{
            height: listVis ? "auto" : initHeight,
          }}
          onStart={() => {
            listVis && (listRef.current.style.zIndex = "1000")
          }}
          onRest={() => {
            !listVis && (listRef.current.style.zIndex = initZindex)
          }}
        >
          {props => (
            <NavList as="ul" style={props} ref={listRef}>
              <li className="Mobile">
                <a href={covidGoogleSheetUrl} target="_blank" rel="nofollow">
                  Facility Covid Status
                </a>
              </li>
              <li className="Mobile">
                <a
                  href="https://www.coronavirus.in.gov/2393.htm"
                  target="_blank"
                  rel="nofollow"
                >
                  Dpt. of health Covid Guidelines
                </a>
              </li>
              <li className="Mobile">
                <a
                  href={
                    "/2020-WoodlandManor-Employee-Return-To-Work-Criteria.pdf"
                  }
                  download
                  target="_blank"
                  rel="nofollow"
                >
                  Return to Work Guidelines
                </a>
              </li>
              <li>
                <a href={seniorMessagesUrl} target="_blank" rel="nofollow">
                  Senior Messages
                </a>
              </li>
              <li>
                <Link to="/hometownMoments">Hometown Moments</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/career">Career</Link>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
            </NavList>
          )}
        </Spring>
      </HeaderBottomMargin>
    </HeadeBottomStyles>
  )
}

export default HeaderBottom
