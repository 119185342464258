type Color = { base: string; lt?: string; dk?: string; hlt?: string }

type Colors = { [key: string]: Color }

const colors: Colors = {
  main: {
    base: "rgb(200, 138, 18)",
    lt: "rgb(200, 138, 18)",
    dk: "rgb(135, 94, 12)",
  },
  link: {
    base: "rgb(59, 173, 49)",
    hlt: "rgb(111, 196, 45)",
  },
  grey: {
    base: "rgb(92, 79, 64)",
    lt: "rgb(130, 118, 78)",
    dk: "rgb(80, 69, 56)",
  },
  success: {
    base: "rgb(200, 138, 18)",
  },
  error: {
    base: "rgb(212, 92, 8)",
  },
}

const color = (color: string, variation?: string, opacity?: number) => {
  let calcedColor
  if (!variation) {
    if (!colors[color].base) {
      calcedColor = colors[color]
    }
    calcedColor = colors[color].base
  } else {
    calcedColor = colors[color][variation as keyof Color]
  }
  if (opacity) {
    calcedColor = calcedColor
      .replace("rgb", "rgba")
      .replace(")", `, ${opacity})`)
  }
  return calcedColor
}

export default color
